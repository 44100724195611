<script setup lang="ts">
  const isMobile = useMediaQuery('(max-width: 767px)');
  const currentSlide = ref(0);

  // computed не работает для itemsToShow, поэтому генерируем статичный объект
  // начинаем с 300px (который по дизайну itemsToShow = 1.5) и добавляем примерно по 0.2 каждые 50 пикселей
  // подобранный коэффициент получен из этого выражения 400x = 1.8 => x = 0.0045
  const getBreakpoints = () => {
    const breakpoints: Record<number, object> = {};
    let width = 300;
    while (width <= 740) {
      breakpoints[width] = {
        itemsToShow: width * 0.0045
      };
      width += 20;
    }
    return breakpoints;
  };

  const awards = [
    {
      image: 1,
      titleText: 'Хорошее место',
      yearsText: '2023 • 2022 • 2021',
      contentText: 'Яндекс'
    },
    {
      image: 0,
      titleText: 'Лучший медцентр',
      yearsText: '2023',
      contentText: '2ГИС'
    },
    {
      image: 3,
      titleText: 'Выбор пациентов',
      yearsText: '2022 • 2021 • 2020',
      contentText: 'СберЗдоровье'
    },
    {
      image: 4,
      titleText: 'Выбор пациентов',
      yearsText: '2022 • 2021 • 2020',
      contentText: 'НаПоправку'
    }
  ];
</script>

<template>
  <div class="awards-container">
    <div class="main-container">
      <MainPageTopAward />
    </div>
    <div class="main-container awards-desktop">
      <MainPageAward
        v-for="award in awards"
        :key="award.titleText"
        v-bind="award"
      />
    </div>
    <ClientOnly>
      <div :class="`awards-mobile ${isMobile ? '' : 'main-container'}`">
        <Carousel
          v-model="currentSlide"
          :items-to-show="3"
          :wrap-around="true"
          :breakpoints="getBreakpoints()"
        >
          <Slide v-for="award in awards" :key="award.titleText">
            <MainPageAward v-bind="award" />
          </Slide>
        </Carousel>
        <div class="main-container mobile-navigation-container">
          <div
            v-for="i in 4"
            :key="i"
            :class="`${currentSlide === i-1 ? 'active': 'carousel-control'}`"
            @click="currentSlide = i-1"
          ></div>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.awards-container {
  display: flex;
  flex-direction: column;
  margin-top: 117px;
  margin-bottom: 50px;
}
.awards-desktop {
  display: flex;
  margin-top: 4px;
  gap: 4px;
  & div:first-child {
    border-radius: 0px 0px 0px 40px;
  }
  & div:last-child {
    border-radius: 0px 0px 40px 0px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}
.awards-mobile {
  margin-top: 16px;
  @media (min-width: 1023px) {
    display: none;
  }
}
.mobile-navigation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}
.carousel-control {
  width: 6px;
  height: 6px;
  background-color: #E0E2E8;
  border-radius: 999px;
  cursor: pointer;
}
.active {
  width: 24px;
  height: 6px;
  background-color: #262633;
  border-radius: 16px;
  cursor: pointer;
}
</style>
